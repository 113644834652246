import { FC, useEffect } from 'react';
import ConditionsProperties from '../../../form-builder/ConditionsProperties';
import { EditorProps, PlaceholderTarget } from '../../../form-builder/FormBuilderTypes';
import PreviewProperties from '../../../form-builder/PreviewProperties';
import { Input } from '../../../shared/form-control/Input';
import ActionEditorBase from '../ActionEditorBase';
import Checkbox, { SliderSize } from '../../../shared/form-control/Checkbox';
import PlaceholderTextBox from '../../../shared/placeholder/PlaceholderTextBox';
import { useTranslation } from 'react-i18next';
import InfoIcon from '../../../shared/icon/InfoIcon';
import Tooltip from '../../../shared/Tooltip';

const TextInputEditor: FC<EditorProps> = (props) => {
  const { action, patchData, patchForm, referencedForms, form, patchTranslations } = props;
  const { translations } = action;
  const { rows = '1', prefilledText, requiresPrefilledText, allowDynamicData } = action?.data || {};
  const { t } = useTranslation('form-builder');

  useEffect(() => {
    if (!!prefilledText && (requiresPrefilledText === undefined || requiresPrefilledText === null)) {
      patchData({ requiresPrefilledText: false });
    }
  }, [prefilledText, patchData, requiresPrefilledText]);

  useEffect(() => {
    if (allowDynamicData === undefined || allowDynamicData === null) {
      patchData({ allowDynamicData: true });
    }
  }, [prefilledText, patchData, requiresPrefilledText, allowDynamicData]);

  return (
    <div data-cy="text-input-editor">
      <ActionEditorBase {...props}>
        <div className="mt-4 flex items-center justify-between">
          <div className="text-dpm-12">
            <Tooltip text={t('action-properties.common.allow-dynamic-data-help')}>
              {(tooltip) => {
                return (
                  <span {...tooltip}>
                    {t('action-properties.common.allow-dynamic-data')}
                    <InfoIcon className="ml-1 inline h-4 w-4" />
                  </span>
                );
              }}
            </Tooltip>
          </div>

          <div>
            <Checkbox
              value={allowDynamicData ?? false}
              onChange={(value) => patchData({ allowDynamicData: value })}
              slider
              sliderSize={SliderSize.S}
            />
          </div>
        </div>
        <div className="mt-4 flex items-center justify-between">
          <div className="text-dpm-12">
            <Tooltip text={t('action-properties.common.requires-pre-filled-text-help')}>
              {(tooltip) => {
                return (
                  <span {...tooltip}>
                    {t('action-properties.common.requires-pre-filled-text')}
                    <InfoIcon className="ml-1 inline h-4 w-4" />
                  </span>
                );
              }}
            </Tooltip>
          </div>
          <div>
            <Checkbox
              value={requiresPrefilledText ?? false}
              onChange={(value) => patchData({ requiresPrefilledText: value })}
              slider
              sliderSize={SliderSize.S}
            />
          </div>
        </div>

        {requiresPrefilledText && (
          <>
            <PlaceholderTextBox
              form={form}
              referencedForms={referencedForms}
              action={{ ...action, targetId: action.id, target: PlaceholderTarget.Action }}
              data-cy="prefilled-text"
              inputPlaceholder={t('action-properties.common.pre-filled-text')}
              initialValue={prefilledText}
              onPlaceholdersChange={(placeholders) => {
                patchForm({ placeholders });
              }}
              enableMarkdown
              enableLanguageToggle
              translationKey="prefilledText"
              translations={translations ?? {}}
              onTranslationsChange={patchTranslations}
            />
          </>
        )}
        <Input data-cy="input-rows" type="number" label="Rows" value={rows} onChange={(e) => patchData({ rows: e.target.value })} min={1} max={50} />
      </ActionEditorBase>

      <ConditionsProperties {...props} />

      <PreviewProperties {...props} enableDocumentNumbering />
    </div>
  );
};

export default TextInputEditor;
